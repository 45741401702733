import Link from "@/components/Link/Link";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import s from "../NavigationCareer/NavigationCareer.module.css";
import * as gtag from "@/lib/gtag";
import { Slot } from "utils/create-slots";
import { Icon } from "@/components/Icon";
import { useRouter } from "next/router";
import { RemoveScroll } from "react-remove-scroll";
import { cx } from "class-variance-authority";

const NavigationCareer = ({ items }: any) => {
  if (!items) return null;
  const [{ children = [] }] = items;

  return (
    <Slot name="Navigation">
      <header
        className={`${s.NavigationContainer}   flex-col pt-5 lg:pt-0 shadow-md transition-colors duration-700 bg-white
      `}
      >
        <div
          className={`container flex-wrap md:flex-nowrap flex items-center justify-between  ${cx(
            RemoveScroll.classNames.fullWidth
          )}`}
        >
          <Link
            href="/"
            title="Zur  Startseite"
            onClick={() => {
              gtag.event({
                event: "itemClick",
                itemClick: "navigation",
                firstLevel: "/",
                secondLevel: "Startseite",
                position: "above",
              });
            }}
          >
            <Icon
              type="logo"
              className={`transition-all duration-700 text-blue-dark w-[170px]  mr-7`}
            />
          </Link>
          <NavigationMenu.Root
            className={`${s.NavigationRoot} [&>div]:!w-full !md:order-[9999]  sm:!order-[0] lg:!order-[0] w-full  no-scrollbar overflow-x-auto xl:overflow-visible`}
          >
            <NavigationMenu.List className="  lg:!text-base text-sm xl:flex-grow select-none flex items-center xl:items-stretch justify-between  mt-3 xl:mt-0 md:justify-center xl:gap-11 md:gap-9">
              {children.map(({ title, link, target }) => (
                <NavigationLink
                  href="/[[...slug]]"
                  as={link}
                  key={`${title}-${link}`}
                  transparentheader={false}
                >
                  {title}
                </NavigationLink>
              ))}
            </NavigationMenu.List>
          </NavigationMenu.Root>
        </div>
      </header>
    </Slot>
  );
};
const NavigationLink = ({ href, as, transparentheader, ...props }) => {
  const router = useRouter();
  const isActive = router.asPath.split("/").join("") === as.split("/").join("");
  return (
    <NavigationMenu.Item
      data-state={isActive ? "active" : "inactive"}
      className={`transition-all duration-700 block font-bold whitespace-nowrap xl:inline-block xl:mt-0  text-blue-dark pt-4 pb-4 xl:py-7 border-b-4  border-transparent  hover:border-b-orange-light active:border-b-orange-light hover:text-blue-light focus-within:border-b-orange-light focus-within:text-blue-light mr-6 md:mr-0`}
      onClick={() => {
        gtag.event({
          event: "itemClick",
          itemClick: "navigation",
          firstLevel: `${props.children}`,
          position: "above",
        });
      }}
    >
      <Link href={href} passHref as={as} legacyBehavior>
        <NavigationMenu.Link active={isActive} {...props} />
      </Link>
    </NavigationMenu.Item>
  );
};

NavigationCareer.displayName = "NavigationCareer";

export default NavigationCareer;
