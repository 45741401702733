//@ts-nocheck
import { FC, Fragment } from "react";
import Rhombus from "@/components/Masks/Rhombus";
import { Button } from "@/components/Button/Button";
import Link from "next/link";
import Image from "next/image";
import { cva } from "class-variance-authority";
import Slider from "@/components/Slider/Slider";
import { Slot } from "utils/create-slots";
import * as gtag from "@/lib/gtag";
import { useRouter } from "next/router";
interface ModelOverviewgrid {
  title: string;
  items: any[];
}

const modelOverviewGridStyles = cva(" ", {
  variants: {
    grid: {
      "layout-1":
        "grid xl:grid-cols-6 sm:grid-cols-3 grid-cols-2  gap-x-9 gap-y-7",
      "layout-2": "flex flex-row flex-nowrap justify-start ",
      default:
        "grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 lg:gap-x-15 lg:gap-y-10 gap-y-6 gap-x-8 w-full h-full lg:mt-8 mt-4",
    },
    fontOfferLink: {
      "layout-1": "text-white  lg:text-2xl text-base",
      "layout-2":
        "text-white lg:text-base text-sm underline underline-offset-4 decoration-2",
      default: "text-blue-medium xl:text-3xl lg:text-2xl text-sm",
    },
    rhombus: {
      "layout-1": "text-blue-light",
      "layout-2": "text-blue-light group-hover:text-[#66bad6] !p-0",
      default: "text-blue-medium",
    },
    button: {
      "layout-1": " max-h-[30px] lg:!text-sm !text-xs !w-full",
      "layout-2": "!hidden",
      default: "lg:!text-base !text-xs",
    },
    wrapper: {
      "layout-1": "bg-blue-dark pb-8 lg:pb-9 xl:pb-12 lg:pt-8 ",
      "layout-2": "bg-blue-dark !p-0 ",
      default: "",
    },
    tile: {
      "layout-1": "flex flex-col justify-start relative w-full h-full group",
      "layout-2":
        "flex group lg:min-w-[300px] h-full shrink-0 [&>div]:flex  [&>div]:justify-start  [&>div]:items-center hover:lg:bg-[#005E7C] py-6 px-8",
      default: "flex flex-col justify-start relative w-full h-full group ",
    },
  },
  defaultVariants: {
    default: "default",
  },
});

const ModelOverviewGrid: FC<ModelOverviewgrid> = ({ content, appearance }) => {
  const { layout = "default" } = appearance;
  const { settings, data } = content.data;
  const startIndex = data.findIndex((item) => item.active);
  const router = useRouter();

  function transformPathToTitle(asPath) {
    if (!asPath) return "";
    const cleanedPath = asPath.replace(/^\/|\/$/g, "");
    const transformedPath = cleanedPath
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-");
    return transformedPath;
  }

  const handleClick = (item) => {
    try {
      if (!item || !item.title || !item.offerLink) {
        console.warn("Invalid item:", item);
        return;
      }
      gtag.event({
        event: "itemClick",
        itemClick: "navigation",
        firstLevel: transformPathToTitle(router.asPath),
        secondLevel: item.title,
        clickURL: item.offerLink,
        position: "above",
      });
    } catch (error) {
      console.error("Error logging itemClick:", error);
    }
  };

  return layout === "layout-2" ? (
    <div className="bg-blue-dark lg:mt-8 mt-3 min-h-[83px]">
      <div className="lg:container mx-auto">
        <Slider
          trackClassName="lg:container mx-auto lg:max-w-[90%] max-w-[100%]"
          options={{
            perPage: 4,
            perMove: 1,
            start: startIndex || 0,
            breakpoints: {
              640: {
                perPage: 2,
                padding: { right: "100px" },
              },
              1200: {
                perPage: 3,
              },
              1440: {
                perPage: 3,
              },
              1920: {
                perPage: 4,
              },
            },

            drag: true,
            pagination: false,
          }}
        >
          {data.map((item, index) => {
            return (
              <Slider.Slide key={index} className="min-w-max min-h-[83px]">
                <Link
                  onClick={() => handleClick(item)}
                  href={item.offerLink || ""}
                  tabIndex={1}
                  className={`${modelOverviewGridStyles({ tile: layout })} ${
                    layout === "layout-1" && index > 5 ? "hidden" : ""
                  } ${
                    item.active &&
                    "!bg-blue-light cursor-default pointer-events-none"
                  } select-none`}
                >
                  <div>
                    <div className={` w-full  h-full  relative `}>
                      <div className="relative">
                        <div className="absolute inset-0 z-0">
                          <Rhombus
                            className={`h-full ${modelOverviewGridStyles({
                              rhombus: layout,
                            })} ${
                              item.active && "!text-[#66bad6]"
                            }  lg:p-4 p-2 min-h-[55px]`}
                          />
                        </div>
                        <div
                          className={`relative ${
                            layout === "layout-2" ? "max-w-[104px]" : ""
                          }`}
                        >
                          {item.image && (
                            <Image
                              className={`group-hover:scale-105 transition-transform z-10 object-contain relative `}
                              src={item.image}
                              alt="image"
                              quality={75}
                              width="325"
                              height="50"
                              sizes="(max-width: 767px) 50vw, (max-width: 1279px) 33vw, 25vw"
                              priority={false}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full  ">
                    <div>
                      <p
                        className={`${modelOverviewGridStyles({
                          fontOfferLink: layout,
                        })} text-center truncate  font-bold  lg:my-6 my-4 `}
                      >
                        {item.title || "Title"}
                      </p>
                    </div>
                  </div>
                </Link>
              </Slider.Slide>
            );
          })}
          <Slider.Slide
            key="lastTile-n"
            className="lg:max-w-[10px] lg:min-w-[1px] min-w-[280px] list-item lg:opacity-0"
          >
            <div className="text-transparent">Last Tile</div>
          </Slider.Slide>

          <Slot name="arrows">
            <Slider.Arrows
              variant="bluishGray"
              position="outside"
              className="[&>button>svg]:!w-6 lg:flex hidden"
            />
          </Slot>
        </Slider>
      </div>
    </div>
  ) : (
    <div className={`${modelOverviewGridStyles({ wrapper: layout })} `}>
      <div
        className={`container overflow-auto no-scrollbar  ${
          layout === "layout-2" ? "p-0" : ""
        }`}
      >
        <Fragment>
          {settings.header &&
            layout !== "layout-1" &&
            layout !== "layout-2" && (
              <Fragment>
                <h1 className="text-5xl lg:text-6xl text-center p-8 font-bold text-blue-dark">
                  {settings.header}
                </h1>
              </Fragment>
            )}
          {settings.header && layout === "layout-1" && (
            <Fragment>
              <h2 className="text-4xl md:text-5xl xl:text-6xl  p-8  text-center  font-bold text-white">
                {settings.header}
              </h2>
            </Fragment>
          )}
        </Fragment>
        <div className={`${modelOverviewGridStyles({ grid: layout })}`}>
          {data.map((item, index) => {
            return (
              <div
                className={`${modelOverviewGridStyles({ tile: layout })} ${
                  layout === "layout-1" && index > 5 ? "hidden" : ""
                } ${
                  item.active &&
                  "!bg-blue-light cursor-default pointer-events-none"
                } select-none`}
                key={index}
              >
                <Link href={item.offerLink}>
                  <div className={` w-full  h-full  relative `}>
                    <div
                      className={`${modelOverviewGridStyles({
                        imageGroup: layout,
                      })}`}
                    >
                      <div className="absolute inset-0 z-0">
                        <Rhombus
                          className={`h-full ${modelOverviewGridStyles({
                            rhombus: layout,
                          })} ${item.active && "!text-[#66bad6]"}  lg:p-4 p-2`}
                        />
                      </div>
                      <div className="relative">
                        <Image
                          className={`group-hover:scale-105 transition-transform z-10 object-contain relative  ${
                            layout === "layout-2" ? "max-w-[104px]" : ""
                          }`}
                          src={item.image}
                          alt="image"
                          quality={75}
                          width="325"
                          height="270"
                          sizes="(max-width: 767px) 50vw, (max-width: 1279px) 33vw, 25vw"
                          priority={index < 8}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="w-full  ">
                  <Link href={item.offerLink}>
                    <p
                      className={`${modelOverviewGridStyles({
                        fontOfferLink: layout,
                      })} text-center truncate  font-bold  lg:my-6 my-4 `}
                    >
                      {item.title}
                    </p>
                  </Link>
                  {item.offerLink && (
                    <Link href={item.offerLink} passHref legacyBehavior>
                      <Button
                        as="a"
                        variant="regular"
                        className={`${modelOverviewGridStyles({
                          button: layout,
                        })} md:w-[85%] w-[95%] mx-auto whitespace-nowrap !p-2 lg:!p-5  `}
                      >
                        {item.count} Angebote
                      </Button>
                    </Link>
                  )}
                  {layout === "default" && item.infoLink && (
                    <Link href={item.infoLink} passHref legacyBehavior>
                      <Button
                        as="a"
                        variant="link"
                        className="lg:!text-base !text-sm !font-normal"
                      >
                        {item.infoText}
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ModelOverviewGrid;
