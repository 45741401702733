import { Fragment } from "react";

interface IndustryListStepperProps {
  activeStep: number;
  setActiveStep: (step: number) => void;
  activeCommercial?: boolean;
  headlines?: string[];
  selectedTitle?: string;
  className?: string;
}

const STEP_CONFIG = [
  {
    step: 1,
    label: "Branche wählen",
    onClick: (setActiveStep: (step: number) => void) => setActiveStep(1),
    isDisabled: (activeStep: number) => activeStep < 1,
  },
  {
    step: 2,
    label: "Gewerbe wählen",
    onClick: (setActiveStep: (step: number) => void) => setActiveStep(2),
    isDisabled: (activeStep: number) => activeStep < 2,
  },
  {
    step: 3,
    label: "Unsere Empfehlung",
    onClick: (
      setActiveStep: (step: number) => void,
      activeCommercial?: boolean
    ) => (activeCommercial ? setActiveStep(3) : undefined),
    isDisabled: (activeStep: number, activeCommercial?: boolean) =>
      activeStep < 3 || !activeCommercial,
  },
];

const IndustryListStepper: React.FC<IndustryListStepperProps> = ({
  activeStep,
  setActiveStep,
  activeCommercial = false,
  headlines = [],
  selectedTitle = "",
  className = "",
}) => {
  const pseudoButtonClassOverwrites =
    "border-2 lg:py-6 lg:px-7 px-4 py-4 font-bold items-center !bg-white !text-blue-dark lg:whitespace-nowrap lg:!justify-start !justify-center flex lg:flex-row flex-col w-1/4 cursor-pointer  rounded-6 !text-[12px] lg:!text-base";

  const getButtonClasses = (
    index: number,
    activeStep: number,
    isDisabled: boolean
  ) => {
    const baseClasses = pseudoButtonClassOverwrites;
    const activeClasses = index + 1 === activeStep ? "border-blue-dark" : "";
    const completedClasses = index + 1 < activeStep ? "border-success" : "";
    const disabledClasses = isDisabled
      ? "opacity-50 cursor-not-allowed"
      : "hover:scale-105";

    return `${baseClasses} ${activeClasses} ${completedClasses} ${disabledClasses}`;
  };

  return (
    <nav className="w-full ">
      <div className={`${className} mx-auto w-full`}>
        {headlines.length > 0 && (
          <p className="font-bold lg:text-4xl text-2xl mt-8 lg:mb-8 mb-6 text-center w-full">
            {activeStep <= 2 ? (
              headlines[activeStep - 1]
            ) : (
              <>
                Hier passende Fahrzeuge für{" "}
                <span className="text-blue-light lg:inline block">
                  {selectedTitle}
                </span>
              </>
            )}
          </p>
        )}

        <div className="w-full flex flex-row flex-wrap justify-between lg:flex-nowrap items-center lg:pb-12 pb-8 pt-2 relative">
          {STEP_CONFIG.map((stepConfig, index) => {
            const isDisabled = stepConfig.isDisabled(
              activeStep,
              activeCommercial
            );
            return (
              <Fragment key={stepConfig.step + index}>
                <div
                  onClick={
                    !isDisabled
                      ? () =>
                          stepConfig.onClick(setActiveStep, activeCommercial)
                      : undefined
                  }
                  className={`${getButtonClasses(
                    index,
                    activeStep,
                    isDisabled
                  )} ${
                    activeCommercial && activeStep === 3 && "!border-success"
                  }`}
                >
                  <div className="w-full flex lg:justify-start justify-center lg:mb-0 mb-2">
                    {getStepIcon(activeStep, stepConfig.step)}
                  </div>
                  <p className="w-full block lg:text-start text-center whitespace-normal xl:whitespace-nowrap">
                    {stepConfig.label}
                  </p>
                </div>

                {index < STEP_CONFIG.length - 1 && (
                  <LineConnector
                    color={activeStep > stepConfig.step ? "success" : "default"}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </nav>
  );
};

const getStepIcon = (activeStep: number, stepNumber: number) => {
  if (activeStep === 3) return <Success />;
  if (activeStep === stepNumber) return <CircleFilled text={stepNumber} />;
  return activeStep > stepNumber ? (
    <Success />
  ) : (
    <CircleFilled text={stepNumber} />
  );
};

const LineConnector = ({ color = "default" }: { color?: string }) => {
  const colorClass = color === "default" ? "border-gray-300" : "border-success";

  return (
    <div className="flex lg:w-full w-[12.5%]">
      <div className={`flex-auto border-t-2 w-full ${colorClass}`}></div>
    </div>
  );
};

const CircleFilled = ({ text }: { text: number }) => {
  return (
    <svg
      className="lg:!w-[30px] w-[30px] lg:!h-[30px] h-[30px] mr-4 "
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <circle cx="9" cy="9" r="8" stroke="#008CBA" strokeWidth="1.5" />
      <text
        x="50%"
        y="55%"
        textAnchor="middle"
        dy=".3em"
        fontSize="8"
        fill="#000"
      >
        {text}
      </text>
    </svg>
  );
};

const Success = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className="lg:!w-[30px] !w-[30px] lg:!h-[30px] !h-[30px] mr-2 lg:scale-[0.8]"
      width={30}
      height={30}
    >
      <rect
        width="24"
        height="24"
        x="1"
        y="1"
        stroke="#4B840C"
        strokeWidth="2"
        rx="12"
      />
      <path
        fill="#4B840C"
        d="m10.823 15.953 7.367-7.367a.864.864 0 1 1 1.222 1.222l-7.978 7.979a.864.864 0 0 1-1.222 0L6.585 14.16a.864.864 0 1 1 1.223-1.222l3.015 3.015Z"
      />
    </svg>
  );
};

export default IndustryListStepper;
