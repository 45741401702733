import Tabs from "@/components/Tabs/Tabs";
import { useState } from "react";

const TabTable = ({ content, header }) => {
  const [val, setVal] = useState(0);

  if (!content) return null;

  return (
    <div className="container lg:my-16 my-8 ">
      <h2 className="text-center lg:text-6xl text-3xl">{header}</h2>
      <div>
        <Tabs.Root
          className="relative"
          onValueChange={(e) => {
            setVal(e);
          }}
          value={`${val}`}
        >
          <Tabs.List
            className={`w-full lg:overflow-y-visible overflow-y-scroll flex mb-8 `}
          >
            <div className={`flex  lg:justify-center justify-start  pb-8`}>
              {content.map((tableTrigger, index) => (
                <Tabs.Trigger
                  value={`${index}`}
                  key={tableTrigger.id}
                  className="active:text-blue-light hover:text-blue-medium hover:border-b-4 active:border-b-4 active:font-bold text-gray-600 px-8 pt-4 pb-8 border-b-2 border-gray-300 active:border-orange-light hover:border-orange "
                >
                  <span className="whitespace-nowrap">
                    {tableTrigger.content.header || ` Tab - ${index + 1}`}
                  </span>
                </Tabs.Trigger>
              ))}
            </div>
          </Tabs.List>

          {content.map((table, index) => {
            return (
              <Tabs.Content
                key={table.header}
                value={`${index}`}
                className="overflow-x-scroll"
              >
                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      {table.content.bodytext[0].map((header, index) => (
                        <th
                          key={index}
                          className={`${
                            index === 0 ? "text-left pl-6" : "text-center"
                          } px-4 py-6 border-2 border-white bg-blue-dark text-white`}
                        >
                          {header || "-"}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {table.content.bodytext
                      .slice(1, table.content.bodytext.length)
                      .map((header, index) => (
                        <tr key={index}>
                          {header.map((item, index) => (
                            <td
                              key={index}
                              className={`px-6 py-8 border-2   ${
                                index === 0
                                  ? "bg-gray-100 text-blue-dark font-bold border-white text-left"
                                  : "bg-white text-gray-600 border-gray-100 text-center"
                              } `}
                            >
                              {item || "-"}
                            </td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Tabs.Content>
            );
          })}
        </Tabs.Root>
      </div>
    </div>
  );
};

export default TabTable;
