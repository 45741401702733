import { Button } from "@/components/Button/Button";
import Image from "@/components/Image/Image";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import { Fragment, PropsWithChildren, FC, useContext } from "react";
import { PageContext } from "pages/[[...slug]]";
import Link from "next/link";
import s from "./JobStage.module.css";
import { Icon } from "@/components/Icon";

interface HeaderLinkI {
  headline?: string;
  subeadline?: string;
  jobs?: any[];
}

interface ContentI {
  bodytext: string;
  buttontext: string;
  header: string;
  headerLink?: string;
  subheader: string;
  assets: any[];
  assetsMobile: any[];
  scrolltext?: string;
  textColor?: "white" | "blue";
  header_layout: any;
  data: HeaderLinkI;
}

const CareerStage: FC<
  PropsWithChildren<{
    className?: string;
    content: ContentI;
    appearance?: any;
    id?: string;
  }>
> = ({ children, className = "", content, id = "" }) => {
  const { data } = content;
  const { jobs } = data;
  const { breadcrumbs } = useContext(PageContext);

  return (
    <Fragment>
      <div
        id={`c${id}`}
        className={`xl:mt-[78px] lg:mt-[50px] md:mt-[58px] mt-[80px]  w-full  min-h-[400px] !bg-gray-100  2xl:min-h-[80vh] xl:min-h-[70vh] lg:min-h-[60vh] relative flex flex-col justify-center lg:items-center items-end p-0 ${className} select-none`}
      >
        {/* {breadcrumbs && (
          <Breadcrumbs items={breadcrumbs} className={"!pt-6 lg:!pt-10"}>
            {breadcrumbs.map(({ link, title }) => (
              <Breadcrumbs.Item href={link} key="link">
                {title}
              </Breadcrumbs.Item>
            ))}
          </Breadcrumbs>
        )} */}
        <div className="grid grid-cols-2 sm:gap-x-7 lg:gap-y-0 gap-y-6 w-full">
          <div className="col-span-2 flex justify-center items-center flex-col lg:py-12 py-8 ">
            <HeadlineContent content={data} />
          </div>
          {jobs?.map((job) => {
            return (
              <div
                className="relative sm:col-span-1 col-span-2 bg-blue-dark"
                key={job.header}
              >
                <ImageTile content={job} />
                {job.usps?.length && <JobStageUsps usps={job.usps} />}
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

const HeadlineContent = ({ content }) => {
  const { headline, subheadline } = content;
  return (
    <Fragment>
      {headline && (
        <h1 className="lg:text-7xl text-5xl text-center max-w-[320px] lg:max-w-full">
          {headline}
        </h1>
      )}
      {subheadline && (
        <p className="lg:text-5xl text-3xl font-light">{subheadline}</p>
      )}
    </Fragment>
  );
};
const ImageTile = ({ content }) => {
  const { imageSplitStage, header = "Header", url = "/" } = content;
  return (
    <div className="relative w-full flex items-end lg:p-15 p-7 lg:min-h-[730px] md:min-h-[630px] min-h-[320px] ">
      <Image
        className="absolute object-cover inset-0 object-top"
        alt="test"
        objectFit="cover"
        layout="fill"
        width={1120}
        height={960}
        src={imageSplitStage}
      />
      <div className="relative flex  justify-center items-end w-full h-full">
        <div className="flex flex-col justify-center items-center">
          <p className="text-white text-center lg:text-6xl text-4xl lg:pb-8 pb-6 font-bold lg:max-w-[450px]">
            {header}
          </p>
          <Link href={url} passHref>
            <Button className="!w-max">Jetzt bewerben</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
const JobStageUsps = ({ usps }) => {
  return (
    <ul className={s.JobStageUsps}>
      {usps?.map((usp, index) => {
        return (
          <li className={s.JobStageUsp} key={index}>
            <Icon type="lineend-skewed-left" className={s.JobStageUsp_Border} />
            <div className={s.JobStageUsp_Content}>
              <Icon type="checkmark" className={s.JobStageUsp_Checkmark} />
              {usp}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

CareerStage.displayName = "CareerStage";
export default Object.assign(CareerStage, {
  HeadlineContent,
  ImageTile,
  JobStageUsps,
});
