import { Icon } from "@/components/Icon";
import Link from "@/components/Link/Link";
import { useContext } from "react";
import { WishlistContext } from "./WishlistProvider";

const WishlistButton = () => {
  const { count } = useContext(WishlistContext);

  return count !== 0 ? (
    <Link
      href="/merkliste"
      className="py-3 flex flex-col items-center rounded text-blue-light"
    >
      <span className="relative">
        {count ? (
          <Icon type="star-filled" className="w-[24px] animate-wiggle" />
        ) : (
          <Icon type="star" className="w-[24px] h-[24px]" />
        )}
        <span
          className={`absolute right-0 top-0  text-white bg-orange-light flex h-[16px] items-center px-3 rounded-full text-xs ${
            count > 0 ? "animate-dropdown" : ""
          } `}
        >
          {count}
        </span>
      </span>{" "}
      <span className="hidden xl:inline text-xs leading-2">Merkliste</span>
    </Link>
  ) : (
    <Link
      href="/merkliste"
      className="py-3 flex flex-col items-center rounded text-blue-light opacity-0"
    >
      <span className="relative">
        {count ? (
          <Icon type="star-filled" className="w-[24px] animate-wiggle" />
        ) : (
          <Icon type="star" className="w-[24px] h-[24px]" />
        )}
        <span
          className={`absolute right-0 top-0  text-white bg-orange-light flex h-[16px] items-center px-3 rounded-full text-xs ${
            count > 0 ? "animate-dropdown" : ""
          } `}
        >
          {count}
        </span>
      </span>{" "}
      <span className="hidden xl:inline text-xs leading-2">Merkliste</span>
    </Link>
  );
};

export default WishlistButton;
