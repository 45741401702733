import React, { Fragment } from "react";
import Typo3Headlines from "@/modules/Headlines/Headlines";
import CarModelTeaser from "../Teaser/variants/CarModelTeaser";

const GridGallery = ({ content, appearance, id }) => {
  const { header = "", subheader = "" } = content;
  const { layout = "default" } = appearance;

  const renderContentSection = (sectionContent, isBig) =>
    Object.entries(sectionContent).map(([key, value]) => {
      //@ts-ignore
      return <CarModelTeaser {...value} key={key} isBig={isBig} />;
    });

  return (
    <div className="container lg:my-16 my-9" id={id}>
      {header && <Typo3Headlines subheader={subheader} header={header} />}

      <div className="grid gap-6 grid-cols-1 md:grid-cols-4  md:grid-rows-2 grid-rows-5 md:grid-flow-col lg:mt-9 mt-7">
        {layout === "default" && renderContentSection(content.main, true)}

        {content["1-1"] && (
          <Fragment>{renderContentSection(content["1-1"], false)}</Fragment>
        )}
        {content["1-2"] && (
          <Fragment>{renderContentSection(content["1-2"], false)}</Fragment>
        )}
        {content["2-1"] && (
          <Fragment>{renderContentSection(content["2-1"], false)}</Fragment>
        )}
        {content["2-2"] && (
          <Fragment>{renderContentSection(content["2-2"], false)}</Fragment>
        )}

        {layout === "layout-1" && renderContentSection(content.main, true)}
      </div>
    </div>
  );
};

export default GridGallery;
