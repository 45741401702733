import Link from "@/components/Link/Link";
import DefaultTeaser from "./variants/DefaultTeaser";
import MarketingTeaser from "./variants/MarketingTeaser";

enum TeaserVariantEnum {
  default,
  marketing,
}

const getTeaserVariant = (type, props) => {
  switch (type) {
    case "marketing":
      return <MarketingTeaser {...props} />;
    default:
      return <DefaultTeaser {...props} />;
  }
};

const Teaser = ({ layout, link, content, ...rest }) => {
  const target = content?.link?.target || "_self";
  const href = link || content.link.href;

  return (
    <Link
      target={target}
      href={href}
      className="shadow-lg relative flex h-full"
    >
      {getTeaserVariant(layout, { content, ...rest })}
    </Link>
  );
};

export const Typo3Teaser = ({
  content,
  layout = 0,
  appearance: { frameClass },
}) => {
  return (
    <Teaser
      content={content}
      layout={TeaserVariantEnum[layout]}
      frameClass={frameClass}
      header={content.header}
      subheader={content.subheader}
      imgSrc={content.image?.[0].publicUrl}
      link={content.headerLink}
      linkText={content.linkText || "Mehr anzeigen"}
      width={500}
      height={400}
    />
  );
};

export default Object.assign(Teaser);
