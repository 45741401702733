const Subheadline = ({ activeStep, headlines = [], selectedTitle = "" }) => {
  return (
    <div>
      <p className="font-bold lg:text-4xl text-2xl mt-8 lg:mb-8 mb-6 text-center w-full">
        {activeStep <= 2 ? (
          headlines[activeStep - 1]
        ) : (
          <>
            Hier passende Fahrzeuge für{" "}
            <span className="text-blue-light lg:inline block">
              {selectedTitle}
            </span>
          </>
        )}
      </p>
    </div>
  );
};

export default Subheadline;
