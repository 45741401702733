import { useEffect, useState } from "react";
import Lottie from "lottie-react";

/**
 * Implements the "Lottie for React" plugin
 * @see https://lottiereact.com/
 */
const LottieAnimation = ({ animationJson = null, animationUrl = '', loop = true, autoplay = true }) => {

    const [animationData, setAnimationData] = useState(animationJson);

    useEffect(() => {
        if (!animationUrl) return;

        const fetchAnimationData = async () => {
            try {
                const response = await fetch(animationUrl);
                const data = await response.json();
                setAnimationData(data);
            } catch (error) {
                console.error(error);
                setAnimationData(null);
            }
        }
        fetchAnimationData();
    }, [animationUrl]);

    return (
        !animationData ? null :
        <Lottie
            animationData={animationData}
            autoPlay={autoplay}
            loop={loop}
        />
    );
}

export default LottieAnimation;
