import { FC, PropsWithChildren } from "react";
import { cva } from "class-variance-authority";

const badgeStyles = cva(
  "px-4 py-1 items-center justify-center inline-flex text-center -skew-x-[20deg] [&>*]:skew-x-[20deg] rounded-4",
  {
    variants: {
      color: {
        success: "bg-[#24A501] text-white",
        warning: "bg-orange-light text-white",
        alert: "bg-alert text-white",
        discount: "bg-alert text-white font-bold lg:text-sm text-xs",
        default: "bg-gray-600 opacity-40 text-white",
      },
      size: {
        big: "text-2xl",
        small: "text-xs",
        medium: "text-sm",
      },
    },
    defaultVariants: {
      size: "medium",
      color: "default",
    },
  }
);

const Badge: FC<
  PropsWithChildren<{
    color?: "success" | "warning" | "alert" | "discount" | "default";
    size?: "small" | "medium" | "big";
    className?: string;
  }>
> = ({ children, color = "default", size = "medium", className }) => {
  return (
    <span className={`${badgeStyles({ color, size })} ${className}`}>
      <span className="flex whitespace-nowrap">{children}</span>
    </span>
  );
};

export default Badge;
