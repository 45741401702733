import IconsEnum from "enums/icons";
import { Icon } from "@/components/Icon";
import { BackgroundColorsEnum } from "enums/backgroundColors";

const JobApplicationTile = ({ content, appearance, ...props }) => {
  const { header, text, icon = 41, fontColor = "" } = content;
  const { frameClass } = appearance || {};
  return (
    <div className="w-full h-full flex lg:flex-col lg:justify-start justify-center lg:items-center items-start gap-4">
      <div
        className={`flex w-12 h-12 lg:w-16 lg:h-16 items-center justify-center rounded-full relative ml-6 lg:ml-0 ${
          BackgroundColorsEnum[frameClass || 3]
        } shrink-0`}
      >
        <Icon type={IconsEnum[icon]} className="w-8 h-8 lg:w-10 lg:h-10 text-white" />
        <span className="flex font-bold justify-center items-center text-white bg-orange-medium w-8 h-8 lg:w-10 lg:h-10 border-4 border-gray-100 rounded-full absolute top-0 -left-7">
          {positionMap(props.colPos)}
        </span>
      </div>
      <div
        className={`lg:pl-0 lg:pb-0  lg:pr-0 pl-6 pb-8 pr-4 w-2/3 lg:w-full `}
      >
        <p
          className={`${fontColorHeader(
            fontColor
          )} lg:text-center font-bold  lg:text-2xl text-lg lg:py-6 lg:mb-0 mb-3 `}
        >
          {header}
        </p>
        <p className={`${fontColorText(fontColor)} text-sm lg:text-base `}>
          {text}
        </p>
      </div>
    </div>
  );
};

export default JobApplicationTile;
const positionMap = (colPos) => {
  switch (colPos) {
    case 201:
      return 1;
    case 202:
      return 2;
    case 203:
      return 3;
    case 204:
      return 4;
    case 205:
      return 5;
    default:
      return 1;
  }
};

const fontColorHeader = (value) => {
  switch (value) {
    case "":
      return "text-blue-dark";
    case 0:
      return "text-white";
    default:
      return "text-blue-dark";
  }
};

const fontColorText = (value) => {
  switch (value) {
    case "":
      return "text-gray-600";
    case 0:
      return "text-gray-300";
    default:
      return "text-gray-600";
  }
};
