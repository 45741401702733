import {
  Root,
  Trigger,
  Value,
  Icon as PrimitiveIcon,
  Portal,
  Content,
  ScrollUpButton,
  Viewport,
  Item as SelectItem,
  ItemText,
  ItemIndicator,
  Group as SelectGroup,
  Label,
  Separator,
  ScrollDownButton,
} from "@radix-ui/react-select";
import { useField } from "formik";
import React from "react";
import { Icon } from "../Icon";

//touch media query für native select

const Item = ({
  children,
  value,
  label = "",
  disabled = false,
  touch = false,
  itemClass = "",
  ...props
}) => {
  if (touch) {
    return <option value={value}>{label || value}</option>;
  }

  return (
    <SelectItem
      value={value}
      className={`  ${itemClass} ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      } py-5 px-6 relative text-gray-600 hover:bg-gray-100 focus:bg-gray-100 whitespace-nowrap ${
        disabled ? "opacity-40" : ""
      }`}
      disabled={disabled}
      {...props}
    >
      <ItemText>{children}</ItemText>

      <ItemIndicator
        className={`absolute w-full h-full inset-0 bg-blue-light opacity-10`}
      />
    </SelectItem>
  );
};

const Group = ({ children, label, touch = false }) => {
  if (touch) {
    return (
      <optgroup label={label}>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { touch } as any);
          }
          return child;
        })}
      </optgroup>
    );
  }

  return (
    <SelectGroup className="pt-6 ">
      <Label className="font-bold !px-4 ">{label}</Label>
      {children}
    </SelectGroup>
  );
};

const Select = ({
  children,
  value,
  onValueChange,
  onOpenChange = () => {},
  className = "",
  placeholder = "Select a value",
  ariaLabel = "",
  itemClass = "",
  iconClass = "text-blue-light",
  iconType = "chevron",
  showNativeSelectMobile = true,
}) => {
  const changeHandlerNativeSelect = (e) => {
    const { value = "" } = e.target;
    onValueChange(`${value}`);
  };
  return (
    <div className="relative w-full ">
      <Root
        value={value}
        onValueChange={onValueChange}
        onOpenChange={onOpenChange}
      >
        <Trigger
          aria-label={ariaLabel || placeholder}
          className={`border px-6 py-5 pr-11 relative text-gray-600 bg-gray-100 border-gray-100 text-start whitespace-nowrap min-w-[160px] ${className}`}
        >
          <Value placeholder={placeholder} />
          <PrimitiveIcon
            className={`absolute right-6 py-1 px-2 w-7 text-blue-light ${iconClass}`}
          >
            <Icon type={iconType} className="rotate-90" />
          </PrimitiveIcon>
        </Trigger>
        <Portal className="!z-[100] shadow-2xl">
          <Content
            className="bg-white text-black z-[70] shadow w-full "
            position="popper"
            side="bottom"
            avoidCollisions={false}
          >
            <ScrollUpButton>
              <div className="w-full flex justify-center py-4">
                <Icon
                  type="chevron"
                  purposeLabel="Nach oben scrollen"
                  className="w-6 h-6 text-blue-light -rotate-90"
                />
              </div>
            </ScrollUpButton>

            <Viewport className=" max-h-[400px] overflow-y-auto shadow w-full min-w-[160px]">
              {children}
            </Viewport>
            <ScrollDownButton>
              <div className="w-full flex justify-center py-4">
                <Icon
                  purposeLabel="Nach unten scrollen"
                  type="chevron"
                  className="w-6 h-6 text-blue-light rotate-90"
                />
              </div>
            </ScrollDownButton>
          </Content>
        </Portal>
      </Root>
      {showNativeSelectMobile && (
        <select
          className="z-[39] absolute inset-0 h-full w-full opacity-0 touch:flex hidden"
          onChange={changeHandlerNativeSelect}
        >
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { touch: true } as any);
            }
            return child;
          })}
        </select>
      )}
    </div>
  );
};

export default Object.assign(Select, { Item, Group, Separator });

export const Typo3Select = ({ properties, name, label }) => {
  const [field, meta, helpers] = useField(`['${name}']`);

  return (
    <>
      {label && (
        <div
          className={`mb-3 text-gray-700 ${
            meta.touched && meta.error && "!text-alert"
          }
        ${meta.touched && !meta.error && "!text-success"}`}
        >
          {label}
        </div>
      )}
      <Select
        value={field.value}
        onValueChange={field.onChange}
        className="w-full"
      >
        {Object.entries(properties.options).map(([value, label]) => (
          <SelectItem value={value} key={value}>
            {label as string}
          </SelectItem>
        ))}
      </Select>
    </>
  );
};
