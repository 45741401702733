import Image from "@/components/Image/Image";
import { Icon } from "@/components/Icon";
import Separator from "@/components/Separator/Separator";
import Link from "next/link";

const CarModelTeaser = ({ isBig = false, content }) => {
  const { data } = content;
  const { image = "", title = "", offerLink = "", count = 0 } = data;

  return (
    <div
      className={`relative acspect-[4/3] ${
        isBig
          ? "xl:min-h-[540px] lg:min-h-[420px] min-h-[265px] max-h-[265px] md:max-h-full md:!col-span-2 md:!row-span-2"
          : "col-span-1 row-span-1"
      }  h-full flex flex-col justify-between w-full`}
    >
      {image && (
        <div className="z-0 absolute inset-0">
          <Image
            src={image}
            alt=""
            layout="fill"
            objectFit="cover"
            loading="lazy"
          />
        </div>
      )}
      <div className="left-6 top-4 z-10 relative">
        {title && (
          <div className="font-bold  text-4xl mb-2 text-white">{title}</div>
        )}
      </div>

      <div className="px-6  bottom-4 w-full mt-auto z-10 relative">
        <Separator
          className="!bg-white opacity-40  !h-2"
          orientation="horizontal"
        />

        <Link
          target={"_self"}
          href={offerLink || "#"}
          className="flex items-center lg:py-6 py-5 group justify-end"
        >
          <p className="text-white font-bold text-base mr-3">
            {count > 0 ? count : "Alle"} Angebote ansehen
          </p>
          <Icon
            type="chevron"
            className="w-5 h-5 text-white group-hover:translate-x-2"
            purposeLabel="Mehr erfahren"
          />
        </Link>
      </div>
    </div>
  );
};

export default CarModelTeaser;
