import { Icon } from "@/components/Icon";
import Link from "@/components/Link/Link";
import * as gtag from "@/lib/gtag";

const SearchButton = () => {
  const link = "/fahrzeugsuche";
  const name = "Suche";

  const handleClick = () => {
    gtag.event({
      event: "cta_search",
    });
  };
  return (
    <Link
      href={link}
      onClick={handleClick}
      className="px-5 pt-2 pb-1  rounded-[17px] border-blue-light border-2 flex  group text-blue-light hover:bg-blue-light hover:text-white transition-all duration-300 ease-in-out  "
    >
      <Icon type={"search"} className="w-[14px] h-[14px] !mt-[2px] mr-3 " />
      <p className="text-xs leading-[20px] font-normal ">{name}</p>
    </Link>
  );
};

export default SearchButton;
