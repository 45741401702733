import React from "react";
import { getColPosContainer } from "utils/get-col-pos-container";
import { ColorsEnum } from "enums/colors";
import { Icon } from "@/components/Icon";
import Link from "@/components/Link/Link";
import Headlines from "@/modules/Headlines/Headlines";

const InfoBox = ({ frameClass, children }) => {
  const boxColor = frameClass > 0 ? ColorsEnum[frameClass] : "blue-light";

  return (
    <div
      className={`relative mt-5 md:mt-6 pt-9 px-6 pb-8 md:pt-12 md:px-10 md:pb-11 
        border-2 border-solid rounded-md border-${boxColor}`}
    >
      <Icon
        type="info"
        className={`absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 
          w-10 md:w-12 bg-white text-${boxColor}`}
      />
      {children}
    </div>
  );
};

export default InfoBox;

export const Typo3InfoBox = ({
  colPos,
  appearance,
  content,
}) => {
  const { frameClass = 0 } = appearance || {};
  const { header, subheader, bodytext, link } = content || {};

  return (
    <div className={`${getColPosContainer(colPos)} py-8 lg:py-10`}>
      <InfoBox frameClass={frameClass}>
        {(header || subheader) && (
          <Headlines
            header={header}
            subheader={subheader}
            headerLayout={2}
            className="!text-3xl md:!text-4xl"
          />
        )}

        {bodytext && (
          <div
            dangerouslySetInnerHTML={{ __html: bodytext }}
            className="mt-8 flex flex-col gap-6 justify-center text-center text-gray-600"
          />
        )}

        {link && (
          <div className="mt-7 text-center">
            <Link
              href={link.href}
              className="inline-flex items-center gap-3 text-base text-blue-light hover:text-blue-600 transition-colors"
              {...link}
            >
              mehr erfahren
              <Icon type="chevron" className="w-5 h-5" />
            </Link>
          </div>
        )}
      </InfoBox>
    </div>
  );
};
