enum IconsEnum {
  default,
  attention,
  bell,
  checkmark,
  chevron,
  close,
  comfort,
  dark,
  delivery,
  electric,
  google,
  hybrid,
  light,
  list,
  logo,
  "logo-dm",
  mail,
  one,
  phone,
  photo,
  pickup,
  play,
  plus,
  price,
  rating,
  safety,
  search,
  share,
  speaker,
  speed,
  star,
  "star-filled",
  "st-model",
  suv,
  transporter,
  trash,
  arrow,
  award,
  calendar,
  camera,
  "checkmark-complex",
  clock,
  "clock-invert",
  cookie,
  copy,
  download,
  energy,
  exchange,
  facebook,
  financing,
  form,
  "form-invert",
  fuel,
  heart,
  "heart-fill",
  info,
  label,
  leasing,
  length,
  like,
  menu,
  minus,
  moon,
  pause,
  pen,
  "pen-underline",
  print,
  "print-fill",
  radio,
  request,
  seat,
  send,
  "share-arrow",
  "shopping-cart",
  sort,
  "speaker-full",
  "speaker-little",
  "speaker-no",
  "ssl-lock",
  sun,
  telegram,
  temperature,
  tiles,
  way,
  weight,
  whatsapp,
  winter,
  money,
  education,
  growth,
  sport,
  parking,
  coffee,
  monitor,
  "heart-beat",
  cocktail,
  "thumb-up",
  "repair-tool",
  users,
  "add-user",
  "smile-face",
  euroHouse,
  "open-book",
  conversation,
  formular,
  trade,
  airplane,
  percentage,
  business,
  bildaktion,
  service,
}

export default IconsEnum;
