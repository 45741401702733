import { Icon } from "@/components/Icon";
import Image from "@/components/Image/Image";
import { FC } from "react";
import * as gtag from "@/lib/gtag";
interface LinkModuleI {
  content: any;
  id?: number | undefined;
  className?: string | undefined;
}

const LinkModule: FC<LinkModuleI> = ({
  content,
  id = 1234,
  className = "",
}) => {
  const filteredContent = Object.entries(content).filter(
    ([key]) => key !== "header"
  );
  const preRowItems = filteredContent[0][1];
  const rowItems = Object.values(preRowItems as any);
  const headlineElement = Object.entries(content).filter(
    ([key]) => key === "header"
  );

  return (
    <div className={`${className} container lg:pt-12 pt-8`} id={`c${id}`}>
      {headlineElement &&
        headlineElement[0] &&
        (headlineElement as any)[0][1] && (
          <p className="font-bold text-center lg:text-6xl text-4xl lg:pt-4 lg:pb-9 pt-0 pb-8 ">
            {(headlineElement as any)[0][1]}
          </p>
        )}

      <div className="grid xl:grid-cols-4 md:grid-cols-3  grid-cols-2 lg:gap-7 gap-6 w-full h-full lg:pb-16 pb-9">
        {rowItems &&
          Object.keys(rowItems).map((key) => {
            const topObject = rowItems[key];

            return Object.keys(topObject).map((subKey) => {
              const subObjectArray = topObject[subKey];

              return subObjectArray.map((subObject) => {
                const content = subObject.content;

                return (
                  <LinkTile
                    key={subObject.id}
                    text={content.headerLink.title}
                    link={content.headerLink.href}
                    image={content.gallery.rows[1].columns[1].publicUrl}
                  />
                );
              });
            });
          })}
      </div>
    </div>
  );
};

const LinkTile = ({ text = "Text", link = "link", image = "" }) => {
  const handleClick = () => {
    gtag.event({
      event: "itemClick",
      itemClick: "link_modul",
      itemName: text || "Kacheltext",
      itemType: "button",
    });
  };
  return (
    <a
      className="relative w-full h-full flex group cursor-pointer"
      href={link}
      onClick={handleClick}
    >
      <div className=" w-full h-full ">
        <Image
          src={image}
          alt="Karriere Img"
          objectFit="cover"
          width={400}
          height={300}
        />
      </div>
      <div className="z-10 absolute bottom-1 flex  items-end lg:pb-7 pb-4 justify-center w-full h-[60%] bg-gradient-to-t from-black to-transparent">
        <p
          className={`${
            text.length > 35
              ? "xxl:!px-12 xl:!px-7 lg:!px-6 md:!px-10 px-2"
              : "px-4"
          }  text-center break-normal text-white font-bold underline lg:underline-offset-4 underline-offset-2 md:w-full  w-[165px] lg:text-base text-sm `}
        >
          {text}
          <Icon
            type="arrow"
            className="inline lg:relative lg:w-7 lg:h-7 h-5 w-5 lg:ml-0 ml-2 lg:left-2 lg:group-hover:left-4 ease-in duration-150 text-orange-light group-hover:text-orange-medium"
          />
        </p>
      </div>
    </a>
  );
};

export default LinkModule;
